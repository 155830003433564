import * as React from 'react';

function PhoneIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M16.5554 13.0042L14.1259 11.3068C13.8082 11.0852 13.4369 10.9684 13.0518 10.9684C12.4395 10.9684 11.8647 11.2687 11.5147 11.7711L10.9507 12.5793C10.0055 11.9454 8.95081 11.0603 7.94538 10.0551C6.94013 9.0498 6.05518 7.99512 5.42145 7.04993L6.22931 6.48596C6.64112 6.19922 6.91614 5.76965 7.00348 5.27673C7.09064 4.78418 6.98078 4.2865 6.69367 3.87451L4.99665 1.44507C4.64179 0.9375 4.06922 0.634277 3.46478 0.634277C3.25531 0.634277 3.04987 0.671265 2.85431 0.743408C2.6322 0.825439 2.42529 0.926147 2.22241 1.05359L1.88696 1.28979C1.8031 1.35498 1.7251 1.42639 1.65039 1.5011C1.24115 1.91016 0.950746 2.42798 0.786867 3.04028C0.0875871 5.66162 1.81995 9.62549 5.09754 12.9031C7.84998 15.6555 11.1567 17.3654 13.7269 17.3657H13.7271C14.1673 17.3657 14.5822 17.3145 14.9605 17.2134C15.5728 17.0497 16.0906 16.7593 16.5001 16.3499C16.5744 16.2755 16.6455 16.1975 16.7216 16.099L16.958 15.7617C17.0736 15.5771 17.1741 15.3702 17.2572 15.1465C17.543 14.3738 17.2545 13.4927 16.5554 13.0042Z"
        fill="#E92134"
      />
    </svg>
  );
}

export default PhoneIcon;
