const colors = {
  white: '#FFFFFF',
  darkNero: '#1A1A1A',
  darkBlue: '#01143A',
  darkCharcoal: '#333333',
  gray: '#777777',
  gray65: '#A6A6A6',
  gray97: '#F7F7F7',
  lightGray: '#EEEEEE',
  primary: '#E92134',
  secondary: '#555555',
  lavenderBlush: '#FFF8F9',
  outline: '#83BCE2',
  icon: '#B7B7B7',
  error: '#FB2121',
  border: '#CCCCCC',
  textFieldBackground: '#E0E0E0',
  background: '#F2F2F2',
  disableBtn: '#555555',
};

const sizes = {
  xs: '12px',
  sm: '14px',
  md: '16px',
  lg: '18px',
  xl: '20px',
  xxl: '26px',
};

const fontWeight = {
  regular: 400,
  medium: 500,
  bold: 600,
  bolder: 700,
};

const media = {
  mobileS: '@media (min-width: 320px)',
  mobileM: '@media (min-width: 375px)',
  mobileL: '@media (min-width: 425px)',
  tablet: '@media (min-width: 768px)',
  laptop: '@media (min-width: 1024px)',
  laptopL: '@media (min-width: 1200px)',
  desktop: '@media (min-width: 1400px)',
  desktopL: '@media (min-width: 1600px)',
  desktopXL: '@media (min-width: 1920px)',
};

export const theme = {
  colors,
  sizes,
  fontWeight,
  media,
};

export type Theme = typeof theme;
export type Color = keyof typeof colors;
export type Size = keyof typeof sizes;
export type FontWeight = keyof typeof fontWeight;
