import * as React from 'react';

function DropdownArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="10"
      height="5"
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M5.29688 4.5L9.627 0.75H0.966748L5.29688 4.5Z" fill="#333333" />
    </svg>
  );
}

export default DropdownArrowIcon;
