import React from 'react';
import {
  WrapperAboutUs,
  BlockAboutUs,
  BlockBussiness,
  WrapperBussiness,
  BussinessTitle,
  PAboutUs,
  WrapperAboutUsIcon,
} from './AboutUs.style';
import { ABOUT_US_SECTION_ID } from '@Constant';
import { Title } from '@Components';
import { useTranslation } from 'react-i18next';
import { AgrieIcon, LeberIcon, PtvLogoIcon, QuoteIcon } from '@Icons';
import { Container } from '../LandingPage.style';

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <WrapperAboutUs id={ABOUT_US_SECTION_ID}>
      <Container>
        <BlockAboutUs>
          <Title position={'left'} textAlign={'left'}>
            {t('aboutUs.title')}
          </Title>
          <PAboutUs>{t('aboutUs.aboutUsDescription')}</PAboutUs>
          <PAboutUs>{t('aboutUs.aboutUsDescription1')}</PAboutUs>
          <PAboutUs>{t('aboutUs.aboutUsDescription2')}</PAboutUs>
          <WrapperAboutUsIcon>
            <AgrieIcon className="agrie-icon" />
            <LeberIcon className="leber-icon" />
            <PtvLogoIcon className="ptv-logo-icon" />
          </WrapperAboutUsIcon>
        </BlockAboutUs>
        <BlockBussiness>
          <WrapperBussiness>
            <div className="left">
              <QuoteIcon />
            </div>
            <div className="center">
              <BussinessTitle>{t('aboutUs.businessPhilosophy')}</BussinessTitle>
              <p>{t('aboutUs.businessPhilosophyDes1')}</p>
              <p>{t('aboutUs.businessPhilosophyDes2')}</p>
            </div>
            <div className="right">
              <QuoteIcon />
            </div>
          </WrapperBussiness>
          <div className="bg-color"></div>
        </BlockBussiness>
      </Container>
    </WrapperAboutUs>
  );
};

export default AboutUs;
