import React, { useState } from 'react';

import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import {
  VIETNAMESE_LANGUAGE,
  JAPANESE_LANGUAGE,
  LANGUAGE_DROPDOWN_POPUP_CONTAINER_ID,
} from '@Constant';
import {
  TickIcon,
  JapanFlagIcon,
  VietnamFlagIcon,
  DropdownArrowIcon,
} from '@Icons';
import {
  Image,
  StyledMenu,
  DropDownItem,
  DropDownToggle,
  DropDownContainer,
} from './Header.style';

const LanguageDropdown = () => {
  const { i18n } = useTranslation();
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 768px)',
  });
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    return [VIETNAMESE_LANGUAGE, JAPANESE_LANGUAGE].includes(i18n.language)
      ? i18n.language
      : VIETNAMESE_LANGUAGE;
  });

  const handleChangeLanguage: MenuProps['onClick'] = ({ key }) => {
    i18n.changeLanguage(key).then(() => {
      setSelectedLanguage(key);
    });
  };

  const menu = (
    <StyledMenu
      style={{
        top: '16px',
      }}
      onClick={handleChangeLanguage}
      selectedKeys={[selectedLanguage]}
      items={[
        {
          key: VIETNAMESE_LANGUAGE,
          label: (
            <DropDownItem>
              <div>
                <VietnamFlagIcon />
                <span>Tiếng Việt</span>
              </div>
              {selectedLanguage === VIETNAMESE_LANGUAGE && <TickIcon />}
            </DropDownItem>
          ),
        },
        {
          key: JAPANESE_LANGUAGE,
          label: (
            <DropDownItem>
              <div>
                <JapanFlagIcon />
                <span>日本語</span>
              </div>
              {selectedLanguage === JAPANESE_LANGUAGE && <TickIcon />}
            </DropDownItem>
          ),
        },
      ]}
    />
  );

  return (
    <DropDownContainer id={LANGUAGE_DROPDOWN_POPUP_CONTAINER_ID}>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        trigger={['click']}
        getPopupContainer={() =>
          document.getElementById(LANGUAGE_DROPDOWN_POPUP_CONTAINER_ID)!
        }>
        <DropDownToggle>
          {selectedLanguage === VIETNAMESE_LANGUAGE ? (
            <Image src="/images/vietnam-flag.png" />
          ) : (
            <Image src="/images/japan-flag.png" />
          )}
          {isLargeScreen && <DropdownArrowIcon />}
        </DropDownToggle>
      </Dropdown>
    </DropDownContainer>
  );
};

export default LanguageDropdown;
