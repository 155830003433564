import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import Lightbox from 'react-image-lightbox';
import Slider, { CustomArrowProps } from 'react-slick';

import { PHOTO_GALLERY_SECTION_ID } from '@Constant';
import { Title } from '@Components';
import { CarouselArrowIcon } from '@Icons';
import { photos } from './PhotoGallery.constant';
import {
  Wrapper,
  CarouselItem,
  CarouselContainer,
  ArrowContainer,
} from './PhotoGallery.style';

const NextArrow: React.FC<CustomArrowProps> = (props) => {
  const { className, style, onClick } = props;

  return (
    <ArrowContainer
      onClick={onClick}
      style={{ ...style }}
      className={className}>
      <CarouselArrowIcon />
    </ArrowContainer>
  );
};

const PrevArrow: React.FC<CustomArrowProps> = (props) => {
  const { className, style, onClick } = props;

  return (
    <ArrowContainer
      onClick={onClick}
      style={{ ...style }}
      className={className}>
      <CarouselArrowIcon />
    </ArrowContainer>
  );
};

const PhotoGallery = () => {
  const { t } = useTranslation();
  const [openLightBox, setOpenLightBox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1365,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleOpenLightBox = (idx: number) => {
    setPhotoIndex(idx);
    setOpenLightBox(true);
  };

  return (
    <Wrapper id={PHOTO_GALLERY_SECTION_ID}>
      <Title>{t('gallery')}</Title>
      <CarouselContainer>
        <Slider {...settings}>
          {photos.map((photo, idx) => (
            <CarouselItem key={idx}>
              <img
                src={photo.thumbnail}
                onClick={() => handleOpenLightBox(idx)}
              />
            </CarouselItem>
          ))}
        </Slider>
      </CarouselContainer>
      {openLightBox && (
        <Lightbox
          mainSrc={photos[photoIndex].original}
          nextSrc={photos[(photoIndex + 1) % photos.length].original}
          prevSrc={
            photos[(photoIndex + photos.length - 1) % photos.length].original
          }
          onCloseRequest={() => setOpenLightBox(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % photos.length)
          }
        />
      )}
    </Wrapper>
  );
};

export default PhotoGallery;
