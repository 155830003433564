import * as React from 'react';

function CarrotIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M1 1L6.99953 6L13 1"
        stroke={props.stroke}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default CarrotIcon;
