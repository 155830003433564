import styled from 'styled-components';
import { theme } from '@Theme/theme';
export const ImportantStyled = styled.span`
  color: ${theme.colors.primary};
`;
export const BlockForm = styled.div`
  ${theme.media.mobileS} {
    margin-top: 30px;
  }
  ${theme.media.tablet} {
    margin-top: 50px;
    textarea {
      height: 140px !important;
    }
  }
  ${theme.media.desktopXL} {
    margin-top: 30px;
    width: calc(70% - 50px);
    float: left;
    margin-left: 50px;
    textarea {
      height: 160px !important;
    }
    padding-right: 30px;
  }
`;
export const BlockInfo = styled.div`
  #wrapper-title {
    margin-bottom: 10px;
    overflow: hidden;
  }
  background: #f8efef;
  border-radius: 8px;
  padding: 30px 20px 10px 20px;
  .row {
    display: flex;
    margin: 20px 0px;
    p {
      margin-left: 10px;
      color: ${theme.colors.darkCharcoal};
    }
  }
  ${theme.media.mobileS} {
    .row svg {
      margin-top: 5px;
    }
    .row-location svg {
      width: 45px;
    }
  }
  ${theme.media.tablet} {
    .row-location svg {
      width: 20px;
    }
  }
  ${theme.media.laptop} {
    .row-message svg {
      margin-top: 5px;
    }
  }
  ${theme.media.desktopXL} {
    background: url('images/bg-contact.png') bottom no-repeat #f8efef;
    width: 30%;
    float: left;
    height: 506px;
    .row svg {
      margin-top: 5px;
    }
    .row-location svg {
      width: 40px;
    }
  }
`;
export const BlockContactUsForm = styled.div`
  ${theme.media.laptop} {
    width: 970px;
    margin: auto;
  }
  ${theme.media.desktopXL} {
    width: 100%;
  }
`;
export const WrapperBtn = styled.div`
  display: flex;
  justify-content: center;
  ${theme.media.mobileS} {
    button {
      width: 100%;
    }
  }
  ${theme.media.tablet} {
    button {
      width: 163px;
    }
  }
  ${theme.media.desktopXL} {
    display: block;
  }
`;
export const SubTitle = styled.p`
  color: ${theme.colors.white};
  margin-top: 25px;
  text-align: center;
`;
export const TitleLeftContact = styled.h3`
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.sizes.xl};
  color: ${theme.colors.darkCharcoal};
  width: 100%;
`;
export const BodyContactUs = styled.div`
  background: ${theme.colors.white};
  border: 1px solid #ededed;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 50px;
  ${theme.media.mobileS} {
    padding: 16px;
  }
  ${theme.media.tablet} {
    padding: 20px;
  }
  ${theme.media.laptop} {
    padding: 30px;
  }
  ${theme.media.desktopXL} {
    padding: 20px;
  }
`;
export const WrapperContactUsForm = styled.div`
  background: url('images/contact-us-form.png');
  background-size: cover;
  h2 {
    text-align: center;
    color: ${theme.colors.white};
  }
  ${theme.media.mobileS} {
    padding: 50px 20px;
    h2 {
      font-size: ${theme.sizes.xl};
    }
  }
  ${theme.media.tablet} {
    padding: 75px 20px;
  }
  ${theme.media.laptop} {
    padding: 75px 0px;
    h2 {
      font-size: ${theme.sizes.xxl};
    }
  }
  ${theme.media.desktopXL} {
    padding: 85px 0px;
  }
`;
