import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { menuItems } from '@Constant';
import { CloseIcon, PtvLogoIcon, HamburgerIcon } from '@Icons';
import {
  Wrapper,
  MenuItem,
  LogoText,
  Container,
  LogoSubText,
  StyledDrawer,
  LogoMainText,
  MenuContainer,
  LogoContainer,
} from './Header.style';
import LanguageDropdown from './LanguageDropdown';

const scrollProps = {
  activeClass: 'menu-item-active',
  smooth: true,
  duration: 900,
  spy: true,
  exact: 'true',
  offset: -74,
};

const scrollPropsMobi = {
  ...scrollProps,
  offset: -46,
};

const Header = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1200px)',
  });
  const isSmallScreen = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const toggleDrawer = () => {
    setVisible(!visible);
  };

  return (
    <Wrapper>
      <Container>
        <LogoContainer>
          <PtvLogoIcon />
          {isLargeScreen ? (
            <LogoText>
              <LogoMainText>Patient technologies vietnam</LogoMainText>
              <LogoSubText>
                Whenever, Wherever, Whoever. Bringing the Best to You.
              </LogoSubText>
            </LogoText>
          ) : (
            <LogoMainText>PTV</LogoMainText>
          )}
        </LogoContainer>
        <MenuContainer>
          {isSmallScreen ? (
            <>
              <LanguageDropdown />
              <HamburgerIcon onClick={toggleDrawer} />
            </>
          ) : (
            <>
              {menuItems.map(({ id, key, label }) => (
                <MenuItem to={id} key={key} {...scrollProps}>
                  {t(label)}
                </MenuItem>
              ))}
              <LanguageDropdown />
            </>
          )}
        </MenuContainer>
        <StyledDrawer
          placement="right"
          closable={true}
          closeIcon={<CloseIcon />}
          visible={visible}
          onClose={toggleDrawer}>
          {menuItems.map(({ id, key, label }) => (
            <MenuItem
              to={id}
              key={key}
              {...scrollPropsMobi}
              onClick={toggleDrawer}>
              {t(label)}
            </MenuItem>
          ))}
        </StyledDrawer>
      </Container>
    </Wrapper>
  );
};

export default Header;
