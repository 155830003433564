import React from 'react';

import { useTranslation } from 'react-i18next';

import { OUR_RECRUITMENT_SECTION_ID } from '@Constant';
import { Wrapper, SubTitle, CollapseContainer } from './OurRecruitment.style';
import { Collapse, Title } from '@Components';
import { TesterJa, Frontend, BrSe } from './jobs';

const OurRecruitment: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Wrapper id={OUR_RECRUITMENT_SECTION_ID}>
      <Title>{t('ourRecruitment.title')}</Title>
      <SubTitle>{t('ourRecruitment.description')}</SubTitle>
      <CollapseContainer>
        {/* <Collapse
          title="Tester JLPT N2"
          calendar="15-8-2022"
          salary="Upto 1200$">
          <TesterJa />
        </Collapse> */}
        <Collapse
          title="Frontend Developer"
          calendar="15-8-2022"
          salary="Upto 1200$">
          <Frontend />
        </Collapse>
        <Collapse
          title="Kỹ sư cầu nối (Br-Se) "
          calendar="15-8-2022"
          salary="Upto 1200$">
          <BrSe />
        </Collapse>
      </CollapseContainer>
    </Wrapper>
  );
};

export default OurRecruitment;
