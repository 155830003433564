import styled from 'styled-components';

export const Wrapper = styled.div(({ theme }) => ({
  height: '133px',
  backgroundColor: theme.colors.white,
  borderBottom: '10px solid #01143A',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.media.tablet]: {
    height: '150px',
  },
}));

export const Text = styled.p(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: theme.fontWeight.medium,
  fontSize: theme.sizes.sm,
  color: theme.colors.darkCharcoal,
  textAlign: 'center',
  padding: '0 16px',
}));
