import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import httpClient from '@Configs/httpClient';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import { Button, Input, TextArea, Title } from '@Components';
import { LocationIcon, PhoneIcon, MessageIcon } from '@Icons';
import { EMAIL_REGEX, CONTACT_US_SECTION_ID } from '@Constant';
import {
  WrapperContactUsForm,
  BlockContactUsForm,
  BlockInfo,
  BlockForm,
  ImportantStyled,
  BodyContactUs,
  TitleLeftContact,
  SubTitle,
  WrapperBtn,
} from './ContactUsForm.style';
import { Container } from '../LandingPage.style';
import { theme } from '@Theme/theme';

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('contactUsForm.emailRequired')
    .matches(EMAIL_REGEX, 'contactUsForm.wrongFormat'),
  name: yup.string().required('contactUsForm.nameRequired'),
  msg: yup.string().required('contactUsForm.contentRequired'),
  title: yup.string().required('contactUsForm.titleRequired'),
});

const ContactUsForm = () => {
  const { t }: any = useTranslation();
  const { watch, handleSubmit, control, setValue, trigger } = useForm({
    resolver: yupResolver(schema),
  });
  const [isDisableBtn, setIsDisableBtn] = useState(true);
  const watchEmailField = watch('email');
  const watchNameField = watch('name');
  const watchMsgField = watch('msg');
  const watchTitleField = watch('title');
  useEffect(() => {
    setIsDisableBtn(
      !(watchEmailField && watchNameField && watchMsgField && watchTitleField)
    );
    if (watchNameField) {
      setValue(
        'name',
        watchNameField.trim() !== ''
          ? watchNameField.replace(/(<([^>]+)>)/gi, '')
          : ''
      );
    }

    if (watchEmailField) {
      setValue(
        'email',
        watchEmailField.trim() !== ''
          ? watchEmailField.replace(/(<([^>]+)>)/gi, '')
          : ''
      );
    }
    if (watchTitleField) {
      setValue(
        'title',
        watchTitleField.trim() !== ''
          ? watchTitleField.replace(/(<([^>]+)>)/gi, '')
          : ''
      );
    }
    if (watchMsgField) {
      setValue(
        'msg',
        watchMsgField.trim() !== ''
          ? watchMsgField.replace(/(<([^>]+)>)/gi, '')
          : ''
      );
    }
  }, [watchEmailField, watchNameField, watchMsgField, watchTitleField]);

  const onSubmit = async (data: any) => {
    setIsDisableBtn(true);
    const params = {
      ...data,
    };
    const results = await httpClient.post('api/v1/contact', params, {
      headers: {
        'X-App-Id': process.env.REACT_APP_APP_ID || '',
        'X-Api-Secret': process.env.REACT_APP_API_SECRET_KEY || '',
      },
    });
    setValue('email', '');
    setValue('name', '');
    setValue('msg', '');
    setValue('title', '');
    if (results.data) {
      setIsDisableBtn(false);
      if (results.data.statusCode === 200) {
        toast(t('contactUsForm.successfully'));
      } else {
        toast(t('contactUsForm.error'));
      }
    }
  };
  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (name) {
        trigger(name as any);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  return (
    <WrapperContactUsForm id={CONTACT_US_SECTION_ID}>
      <Container>
        <BlockContactUsForm>
          <Title color={theme.colors.white}>{t('contactUs')}</Title>
          <SubTitle>{t('contactUsForm.subTitle')}</SubTitle>
          <BodyContactUs>
            <BlockInfo>
              <div id="wrapper-title">
                <TitleLeftContact>
                  {t('contactUsForm.contactInformation')}
                </TitleLeftContact>
              </div>
              <div className="row row-location">
                <LocationIcon />
                <p>{t('contactUsForm.address')}</p>
              </div>
              <div className="row">
                <PhoneIcon />
                <p>{t('contactUsForm.phoneNumber')}</p>
              </div>
              <div className="row row-message">
                <MessageIcon />
                <p>{t('contactUsForm.email')}</p>
              </div>
            </BlockInfo>
            <BlockForm>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Controller
                      control={control}
                      name="name"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Input
                          value={value}
                          label={
                            <>
                              {t('contactUsForm.yourName')}
                              <ImportantStyled>*</ImportantStyled>
                            </>
                          }
                          bdr={8}
                          height={48}
                          maxLength={255}
                          marginForm="0 0 22px 0"
                          color={theme.colors.darkCharcoal}
                          error={t(error?.message)}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <Controller
                      control={control}
                      name="email"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Input
                          value={value}
                          label={
                            <>
                              {t('contactUsForm.yourEmail')}
                              <ImportantStyled>*</ImportantStyled>
                            </>
                          }
                          bdr={8}
                          height={48}
                          maxLength={255}
                          marginForm="0 0 22px 0"
                          error={t(error?.message)}
                          color={theme.colors.darkCharcoal}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24}>
                    <Controller
                      control={control}
                      name="title"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Input
                          value={value}
                          label={
                            <>
                              {t('contactUsForm.title')}
                              <ImportantStyled>*</ImportantStyled>
                            </>
                          }
                          bdr={8}
                          height={48}
                          maxLength={255}
                          color={theme.colors.darkCharcoal}
                          marginForm="0 0 22px 0"
                          error={t(error?.message)}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Col>
                  <Col xs={24}>
                    <Controller
                      control={control}
                      name="msg"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextArea
                          value={value}
                          label={
                            <>
                              {t('contactUsForm.yourMessage')}
                              <ImportantStyled>*</ImportantStyled>
                            </>
                          }
                          lhLabel="24px"
                          bdr="8px"
                          height="120px"
                          color={theme.colors.darkCharcoal}
                          padding="11px"
                          error={t(error?.message)}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24}>
                    <WrapperBtn>
                      <Button
                        type="submit"
                        style={{ marginTop: '20px' }}
                        background={'primary'}
                        height="48px"
                        color={'white'}
                        disabled={isDisableBtn}>
                        {t('contactUsForm.sendMessage')}
                      </Button>
                    </WrapperBtn>
                  </Col>
                </Row>
              </form>
            </BlockForm>
          </BodyContactUs>
        </BlockContactUsForm>
      </Container>
      <ToastContainer />
    </WrapperContactUsForm>
  );
};
export default ContactUsForm;
