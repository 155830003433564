import styled from 'styled-components';
import { theme } from '@Theme/theme';
export const WrapperBtnContactUs = styled.div`
  text-align: center;
  ${theme.media.laptop} {
    height: 260px;
    display: flex;
    align-items: center;
  }
`;
export const WrapperTextContactUs = styled.div`
  p {
    font-weight: ${theme.fontWeight.medium};
    color: ${theme.colors.white};
    ${theme.media.mobileS} {
      text-align: center;
      padding: 50px 0px 30px 0px;
      font-size: ${theme.sizes.xl};
    }
    ${theme.media.tablet} {
      padding: 60px 0px 30px 0px;
      width: 600px;
      margin: auto;
      font-size: ${theme.sizes.xxl};
    }
    ${theme.media.laptop} {
      text-align: left;
      padding: 0px;
      font-size: 33px;
      margin: inherit;
    }
  }
  ${theme.media.laptop} {
    height: 260px;
    display: flex;
    align-items: center;
  }
`;
export const BlockContactUs = styled.div`
  padding: 0px 40px;
  background: url('images/contact-us.png');
  width: 100%;
  overflow: hidden;
  height: 260px;
`;
