import React from 'react';
import ReactDOM from 'react-dom/client';

import '@Locales/i18n';
import { LandingPage } from '@Pages';
import { GlobalStyles } from '@Theme/GlobalStyles';
import ThemeProvider from '@Theme/ThemeProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <GlobalStyles />
    <ThemeProvider>
      <LandingPage />
    </ThemeProvider>
  </React.StrictMode>
);
