import React from 'react';

import styled from 'styled-components';

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Banner = () => {
  return (
    <picture>
      <source srcSet="/images/banner-desktop.png" media="(min-width: 1200px)" />
      <source srcSet="/images/banner-ipad1024.png" media="(min-width: 769px)" />
      <source srcSet="/images/banner-ipad768.png" media="(min-width: 426px)" />
      <Image src="/images/banner-mobile.png" />
    </picture>
  );
};

export default Banner;
