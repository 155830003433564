import React from 'react';

import { JobDescriptionText } from '../OurRecruitment.style';

const BrSe: React.FC = () => {
  return (
    <>
      <JobDescriptionText color="darkCharcoal" fontWeight="medium">
        Mô tả công việc:
      </JobDescriptionText>
      <JobDescriptionText>
        - Kỹ sư cầu nối cho đội phát triển ở Việt Nam với khách hàng Nhật
      </JobDescriptionText>
      <JobDescriptionText>
        - Đứng ra làm PIC (Person in charge) cho 1 dự án, là cầu nối chính trao
        đổi bàn bạc với khách hàng
      </JobDescriptionText>
      <JobDescriptionText>
        - Chịu trách nhiệm phân tích yêu cầu, tư vấn bàn bạc với khách hàng lúc
        chuẩn bị dự án
      </JobDescriptionText>
      <JobDescriptionText>
        - Trong quá trình chạy dự án meeting với khách hàng để báo cáo, xử lý
        các vấn đề
      </JobDescriptionText>
      <JobDescriptionText>- Quản lý bàn giao sản phẩm</JobDescriptionText>
      <JobDescriptionText>
        - Nhận yêu cầu tài liệu thiết kế từ khách hàng, kết hợp với comtor
        truyền tải đầy đủ cho team phát triển
      </JobDescriptionText>
      <JobDescriptionText>
        - Hỗ trợ PM, team leader quản lý tiến độ, vấn đề, risk, từ đó báo cáo và
        bàn bạc với khách hàng
      </JobDescriptionText>

      <JobDescriptionText color="darkCharcoal" mt="20px" fontWeight="medium">
        Quyền lợi được hưởng:
      </JobDescriptionText>
      <JobDescriptionText>- Mức lương cạnh tranh + tháng 13</JobDescriptionText>
      <JobDescriptionText>
        - Đóng Bảo hiểm full lương (không tính trợ cấp)
      </JobDescriptionText>
      <JobDescriptionText>
        - Thời gian làm việc: T2-T6 (8h30-5h30), nghỉ T7, CN
      </JobDescriptionText>
      <JobDescriptionText>
        - Nghỉ mát trong nước (gói 5-7 triệu)+ Teambuilding (ít nhất 2 lần/năm)
        + khám sức khỏe định kỳ
      </JobDescriptionText>
      <JobDescriptionText>
        - Tham gia các event của công ty, hoạt động bóng đá
      </JobDescriptionText>
      <JobDescriptionText>
        - Thưởng các ngày lễ, Tết, hỷ,....
      </JobDescriptionText>

      <JobDescriptionText color="darkCharcoal" mt="20px" fontWeight="medium">
        Yêu cầu:
      </JobDescriptionText>
      <JobDescriptionText>
        - Ưu tiên tốt nghiệp đại học chuyên ngành công nghệ thông tin
      </JobDescriptionText>
      <JobDescriptionText>
        - Tiếng Nhật N2 trở lên (Đọc, viết, nghe, nói tốt). Ưu tiên ứng viên
        nghe, nói tốt
      </JobDescriptionText>
      <JobDescriptionText>
        - Có kinh nghiệm làm BrSE 1 năm trở lên
      </JobDescriptionText>
      <JobDescriptionText>
        - Kỹ năng phân tích Requirement, skill lý giải và truyền đạt yêu cầu từ
        khách hàng tới team và ngược lại tốt
      </JobDescriptionText>
      <JobDescriptionText>- Kỹ năng Horenso tốt là bắt buộc</JobDescriptionText>
      <JobDescriptionText>
        - Có kinh nghiệm làm tài liệu thiết kế (BD, DD), tài liệu test bằng
        tiếng Nhật
      </JobDescriptionText>
      <JobDescriptionText>
        - Soft skill: kỹ năng giao tiếp, kỹ năng quản lý, kỹ năng thuyết trình
      </JobDescriptionText>
      <JobDescriptionText>
        - Có kinh nghiệm làm Web Backend Python, PHP, Ruby hoặc Frontend sử dụng
        VueJS, ReactJS. Hoặc có kinh nghiệm làm các dự án mobile app (iOS,
        Android)
      </JobDescriptionText>
      <JobDescriptionText>
        - Có hiểu biết hoặc có kinh nghiệm về AWS, cloud
      </JobDescriptionText>
      <JobDescriptionText>- Có N1 là một lợi thế lớn</JobDescriptionText>
      <JobDescriptionText>- Có chứng chỉ AWS là một lợi thế</JobDescriptionText>
      <JobDescriptionText>
        - Có kinh nghiệm làm việc ở Nhật từ 1 đến 2 năm trở lên là một lợi thế
      </JobDescriptionText>
      <JobDescriptionText>
        - Có kinh nghiệm management là một lợi thế
      </JobDescriptionText>

      <JobDescriptionText color="darkCharcoal" mt="20px" fontWeight="medium">
        Hồ sơ bao gồm:
      </JobDescriptionText>
      <JobDescriptionText>
        Ứng viên quan tâm vui lòng gửi CV nêu tóm tắt đầy đủ kinh nghiệm làm
        viêc và quá trình học tập của bản than về địa chỉ email:{' '}
        <JobDescriptionText color="primary" display="inline-block">
          linh.nguyen@ptvltd.com
        </JobDescriptionText>
      </JobDescriptionText>
    </>
  );
};

export default BrSe;
