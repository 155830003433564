import * as React from 'react';

function PtvLogoIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5748 32H26.5045C29.5831 32 32.0793 29.51 32.0793 26.439V5.56102C32.0793 2.49001 29.5831 0 26.5045 0H5.5748C2.49618 0 0 2.49001 0 5.56102V26.439C0 29.51 2.49618 32 5.5748 32ZM21.5731 8.58309L25.3542 12.3548C25.6788 12.6787 25.6788 13.2039 25.3542 13.5264L19.2201 19.6453L15.439 23.417C15.3312 23.5245 15.2003 23.5966 15.0625 23.632C14.7938 23.7014 14.4978 23.6361 14.2823 23.4333C14.2796 23.4306 14.2765 23.4279 14.2734 23.4252C14.2704 23.4224 14.2673 23.4197 14.2646 23.417L10.4835 19.6453L7.50852 16.6777C6.14039 15.3129 6.14039 13.0991 7.50852 11.7344C8.87664 10.3696 11.0959 10.3696 12.464 11.7344L14.3109 13.5781C14.6179 13.8516 15.083 13.8516 15.3899 13.5794L20.3986 8.58309C20.7219 8.25925 21.2484 8.25925 21.5731 8.58309Z"
        fill="#E92134"
      />
    </svg>
  );
}

export default PtvLogoIcon;
