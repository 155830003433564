import React from 'react';
import styled from 'styled-components';
import { WrapperErrorMessage, ErrorMessage } from '@Theme/CommonStyle';

interface IInput {
  label?: React.ReactNode;
  height?: number;
  width?: number;
  fs?: number;
  fw?: number;
  fsLabel?: number; // font size
  fwLabel?: number; // font weight
  lhLabel?: string; // line height
  value?: string;
  name?: string;
  type?: string;
  color?: string;
  onChange?: any;
  error?: string;
  marginForm?: string;
  bdr?: number; // border-radius
  ref?: any;
  placeholder?: string;
  disabled?: boolean;
  onBlur?: any;
  maxLength?: any;
  autoComplete?: string;
}

const FormControl = styled.div<IInput>(({ marginForm }) => ({
  position: 'relative',
  margin: marginForm,
}));

const Label = styled.div<IInput>(
  ({ theme, fsLabel, fwLabel, lhLabel, color }) => ({
    color: color || theme.colors.white,
    fontSize: fsLabel || theme.sizes.sm,
    fontWeight: fwLabel || theme.fontWeight.medium,
    lineHeight: lhLabel || '12px',
    marginBottom: 8,
  })
);

const InputStyled = styled.input<IInput>(
  ({ theme, width, height, fs, fw, bdr, color }) => ({
    height: height || 40,
    width: width || '100%',
    borderRadius: bdr || 6,
    color: color || theme.colors.white,
    border: `1px solid #EDEDED`,
    fontSize: fs || theme.sizes.sm,
    fontWeight: fw || theme.fontWeight.medium,
    lineHeight: '20px',
    padding: '0px 11px',
    outline: 'none',
    background: 'none',
    ':focus': {
      border: `1px solid ${theme.colors.outline}`,
    },
    '::placeholder': {
      color: theme.colors.icon,
    },
  })
);

const Input: React.FC<IInput> = ({
  label,
  error,
  fsLabel,
  fwLabel,
  lhLabel,
  marginForm,
  autoComplete,
  color,
  ...rest
}) => {
  return (
    <FormControl marginForm={marginForm}>
      {label && (
        <Label
          fsLabel={fsLabel}
          fwLabel={fwLabel}
          lhLabel={lhLabel}
          color={color}>
          {label}
        </Label>
      )}
      <InputStyled
        {...rest}
        color={color}
        spellCheck="false"
        autoComplete={autoComplete}
      />
      {error && (
        <WrapperErrorMessage>
          <ErrorMessage>{error}</ErrorMessage>
        </WrapperErrorMessage>
      )}
    </FormControl>
  );
};

export default Input;
