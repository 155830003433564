import React, { useState } from 'react';

import { Collapse, CollapseProps } from 'antd';

import { theme } from '@Theme/theme';
import { CarretIcon, CalendarIcon, SalaryIcon } from '@Icons';
import {
  SubHeader,
  SubHeaderText,
  StyledCollapse,
  HeaderContainer,
  SubHeaderContainer,
} from './Collapse.style';

const { Panel } = Collapse;
interface CollapseHeaderProps {
  title: string;
  salary: string;
  calendar: string;
}

interface CustomCollapseProps extends CollapseProps, CollapseHeaderProps {}

const CollapseHeader: React.FC<CollapseHeaderProps> = ({
  title,
  salary,
  calendar,
}) => {
  return (
    <HeaderContainer>
      <span>{title}</span>
      <SubHeaderContainer>
        <SubHeader>
          <CalendarIcon />
          <SubHeaderText>{calendar}</SubHeaderText>
        </SubHeader>
        <SubHeader>
          <SalaryIcon />
          <SubHeaderText>{salary}</SubHeaderText>
        </SubHeader>
      </SubHeaderContainer>
    </HeaderContainer>
  );
};

const CustomCollapse: React.FC<CustomCollapseProps> = ({
  title,
  salary,
  calendar,
  children,
  ...rest
}) => {
  const [active, setActive] = useState(false);

  const handleChange = (key: string | string[]) => {
    setActive(!!key);
  };

  return (
    <StyledCollapse
      accordion
      bordered={false}
      isActive={active}
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <CarretIcon
          style={{
            transform: isActive
              ? 'rotate(-180deg) translateY(50%)'
              : 'rotate(0) translateY(-50%)',
          }}
          stroke={isActive ? theme.colors.primary : theme.colors.darkCharcoal}
        />
      )}
      onChange={handleChange}
      {...rest}>
      <Panel
        header={
          <CollapseHeader title={title} calendar={calendar} salary={salary} />
        }
        key="1">
        {children}
      </Panel>
    </StyledCollapse>
  );
};

export default CustomCollapse;
