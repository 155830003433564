import * as React from 'react';

function CalendarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M14.4127 5.06878H14.1438C13.9317 5.06878 13.7598 4.89685 13.7598 4.68478C13.7598 4.47272 13.9317 4.30078 14.1438 4.30078H14.4127C14.6248 4.30078 14.7967 4.47272 14.7967 4.68478C14.7967 4.89685 14.6248 5.06878 14.4127 5.06878Z"
        fill="#777777"
      />
      <path
        d="M15.0524 1.46011H13.152V0.541715C13.152 0.329651 12.9801 0.157715 12.768 0.157715C12.5559 0.157715 12.384 0.329651 12.384 0.541715V1.46011H3.616V0.541715C3.616 0.329651 3.44406 0.157715 3.232 0.157715C3.01994 0.157715 2.848 0.329651 2.848 0.541715V1.46011H0.906176C0.406496 1.46011 0 1.86664 0 2.36629V14.8428C0 15.394 0.448416 15.8424 0.999616 15.8424H14.9993C15.5511 15.8424 16 15.3935 16 14.8417V2.4077C16 1.8852 15.5749 1.46011 15.0524 1.46011ZM15.232 14.8417C15.232 14.97 15.1276 15.0744 14.9993 15.0744H0.999616C0.871904 15.0744 0.768 14.9705 0.768 14.8428V5.06882H12.9065C13.1186 5.06882 13.2905 4.89688 13.2905 4.68482C13.2905 4.47275 13.1186 4.30082 12.9065 4.30082H0.768V2.36629C0.768 2.2901 0.829984 2.22811 0.906176 2.22811H2.848V2.68571C2.848 2.89778 3.01994 3.06972 3.232 3.06972C3.44406 3.06972 3.616 2.89778 3.616 2.68571V2.22811H12.384V2.68571C12.384 2.89778 12.5559 3.06972 12.768 3.06972C12.9801 3.06972 13.152 2.89778 13.152 2.68571V2.22811H15.0524C15.1514 2.22811 15.232 2.30869 15.232 2.4077V14.8417Z"
        fill="#777777"
      />
      <path
        d="M6.53632 12.9356L4.56867 11.5813C4.39398 11.4611 4.34982 11.222 4.47005 11.0473C4.59027 10.8726 4.82937 10.8285 5.00406 10.9487L6.70416 12.1188L10.9375 7.74771C11.0851 7.59536 11.3282 7.59148 11.4805 7.73904C11.6329 7.88659 11.6368 8.12969 11.4892 8.28201L7.02985 12.8864C6.93853 12.9807 6.65053 13.027 6.53632 12.9356Z"
        fill="#777777"
      />
    </svg>
  );
}

export default CalendarIcon;
