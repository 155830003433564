import * as React from 'react';

function MessageIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M13.2573 7.10266L19.9998 11.3652V2.66016L13.2573 7.10266Z"
        fill="#E92134"
      />
      <path d="M0 2.66016V11.3652L6.7425 7.10266L0 2.66016Z" fill="#E92134" />
      <path
        d="M18.7501 0.125H1.2501C0.626348 0.125 0.131348 0.59 0.0375977 1.18875L10.0001 7.7525L19.9626 1.18875C19.8688 0.59 19.3738 0.125 18.7501 0.125Z"
        fill="#E92134"
      />
      <path
        d="M12.1125 7.85769L10.3438 9.02269C10.2388 9.09144 10.12 9.12519 10 9.12519C9.88004 9.12519 9.76129 9.09144 9.65629 9.02269L7.88754 7.85645L0.0400391 12.8202C0.136289 13.4139 0.628789 13.8752 1.25004 13.8752H18.75C19.3713 13.8752 19.8638 13.4139 19.96 12.8202L12.1125 7.85769Z"
        fill="#E92134"
      />
    </svg>
  );
}

export default MessageIcon;
