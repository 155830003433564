import styled from 'styled-components';
import { theme } from '@Theme/theme';

export const Container = styled.div`
  ${theme.media.tablet} {
    max-width: 100%;
    margin: auto;
  }
  ${theme.media.laptop} {
    max-width: 100%;
  }
  ${theme.media.laptopL} {
    max-width: 1170px;
  }
  ${theme.media.desktop} {
    max-width: 1170px;
  }
`;
