import styled from 'styled-components';
import { theme } from '@Theme/theme';

export const BlockAboutUs = styled.div`
  margin: auto;
  background: #fff;

  ${theme.media.mobileS} {
    width: 100%;
    padding: 20px;
  }
  ${theme.media.laptop} {
    width: 70%;
    padding: 50px;
  }
  ${theme.media.laptopL} {
    width: 60%;
    float: right;
  }
`;
export const PAboutUs = styled.p`
  margin-top: 20px;
  font-size: ${theme.sizes.sm};
  font-weight: ${theme.fontWeight.regular};
`;
export const WrapperAboutUsIcon = styled.div`
  display: flex;
  margin-top: 25px;
  align-items: center;
  svg {
    margin-right: 12px;
    ${theme.media.mobileL} {
      margin-right: 20px;
    }
  }

  .agrie-icon {
    width: 99.27px;
    height: 21.44px;
    ${theme.media.mobileL} {
      width: auto;
      height: auto;
    }
  }

  .leber-icon {
    width: 57px;
    height: 29px;
    ${theme.media.mobileL} {
      width: auto;
      height: auto;
    }
  }

  .ptv-logo-icon {
    width: 40px;
    height: 39px;
    ${theme.media.mobileL} {
      width: 56px;
      height: 55px;
    }
  }
`;
export const BussinessTitle = styled.p`
  color: ${theme.colors.primary};
  font-size: ${theme.sizes.lg};
  font-weight: ${theme.fontWeight.medium};
  margin-bottom: 5px;
`;
export const WrapperBussiness = styled.div`
  height: 140px;
  display: flex;
  width: 405px;
  padding: 0px 5px;
  .center {
    flex: 1;
    margin: 30px 0 30px 15px;
    font-size: 13px;
    ${theme.media.mobileL} {
      margin: 30px 12px;
      font-size: ${theme.sizes.sm};
    }
  }
  .left,
  .right {
    position: absolute;
    height: 140px;
    width: 32px;
    border: 2px solid ${theme.colors.primary};
    ${theme.media.mobileL} {
      position: relative;
    }
  }
  .left {
    border-right: none;
    svg {
      position: absolute;
      left: 10px;
      top: 10px;
    }
  }
  .right {
    right: 5px;
    border-left: none;
    svg {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
    ${theme.media.mobileL} {
      right: 0;
    }
  }
  ${theme.media.mobileL} {
    padding: 0px 9px;
  }
  ${theme.media.tablet} {
    padding: 0px;
  }
`;
export const BlockBussiness = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  position: relative;
  ${theme.media.mobileS} {
    width: 100%;
    padding: 30px 0px 0px 0px;
    .bg-color {
      top: 22px;
    }
  }
  ${theme.media.laptop} {
    width: 50%;
    padding: 60px 0px 0px 0px;
    .bg-color {
      top: 53px;
    }
  }
  ${theme.media.laptopL} {
    width: 40%;
    margin-top: 80px;
    float: right;
    justify-content: flex-start;
    .bg-color {
      left: -8px;
    }
  }
  .bg-color {
    position: absolute;
    background: #e92134;
    opacity: 0.04;
    width: 420px;
    height: 140px;
    max-width: 100%;
  }
`;
export const WrapperAboutUs = styled.div`
  background: url('images/about-us.png');
  ${theme.media.mobileS} {
    padding: 50px 20px;
  }
  ${theme.media.laptop} {
    padding: 60px;
  }
`;
