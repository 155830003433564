import * as React from 'react';

function TickIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.7212 0.461362C11.3499 0.0895851 10.747 0.0898195 10.3752 0.461362L4.31744 6.51936L1.62503 3.82697C1.25325 3.45519 0.650609 3.45519 0.278832 3.82697C-0.0929441 4.19875 -0.0929441 4.80139 0.278832 5.17317L3.6442 8.53854C3.82998 8.72431 4.07357 8.81743 4.31718 8.81743C4.5608 8.81743 4.80463 8.72454 4.9904 8.53854L11.7212 1.80753C12.0929 1.43602 12.0929 0.833115 11.7212 0.461362Z"
        fill="#E92134"
      />
    </svg>
  );
}

export default TickIcon;
