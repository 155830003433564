import React from 'react';

import { JobDescriptionText } from '../OurRecruitment.style';

const Frontend: React.FC = () => {
  return (
    <>
      <JobDescriptionText color="darkCharcoal" fontWeight="medium">
        Mô tả công việc:
      </JobDescriptionText>
      <JobDescriptionText>
        - Tham gia phát triển các dự án về Web của công ty với khác hàng nước
        ngoài: Nhật, chuyên xây dựng các chức năng frontend & backend của
        Website, Web application.
      </JobDescriptionText>
      <JobDescriptionText>
        - Nghiên cứu, tìm hiểu các công nghệ mới, các tài liệu liên quan để phát
        triển dự án.
      </JobDescriptionText>
      <JobDescriptionText>
        - Một số công việc khác trao đổi khi phỏng vấn
      </JobDescriptionText>

      <JobDescriptionText color="darkCharcoal" mt="20px" fontWeight="medium">
        Quyền lợi được hưởng:
      </JobDescriptionText>
      <JobDescriptionText>
        - Mức lương cạnh tranh + tháng 13{' '}
      </JobDescriptionText>
      <JobDescriptionText>
        - Thời gian làm việc: T2-T6 (8h30-5h30), nghỉ T7, CN
      </JobDescriptionText>
      <JobDescriptionText>
        - Nghỉ mát trong nước (gói 5-7 triệu)+ Teambuilding (ít nhất 2 lần/năm)
        + khám sức khỏe định kỳ
      </JobDescriptionText>
      <JobDescriptionText>
        - Thưởng các ngày lễ, Tết, hỷ,....
      </JobDescriptionText>

      <JobDescriptionText color="darkCharcoal" mt="20px" fontWeight="medium">
        Yêu cầu:
      </JobDescriptionText>
      <JobDescriptionText>
        - Có từ 1 năm kinh nghiệm làm việc với vị trí frontend developer
      </JobDescriptionText>
      <JobDescriptionText>
        - Có kinh nghiệm slicing giao diện từ file design như figma, sketch,
        invision...
      </JobDescriptionText>
      <JobDescriptionText>
        - Có kinh nghiệm làm việc với ít nhất 1 ngôn ngữ: JavaScript,
        TypeScript...
      </JobDescriptionText>
      <JobDescriptionText>
        - Có kinh nghiệm làm việc với: Reactjs, Redux pattern...
      </JobDescriptionText>
      <JobDescriptionText>
        - Có kinh nghiệm về: HTML5, CSS3, JSON, JWT, synchronous/asynchronous...
      </JobDescriptionText>
      <JobDescriptionText>
        - Có kinh nghiệm về các framework UI như: ant design/semantic
        ui/material UI...
      </JobDescriptionText>
      <JobDescriptionText>
        - Có kinh nghiệm với database: MongoDB, Mysql, MSSQL, PostgreSQL, Oracle
        ... là một lợi thế
      </JobDescriptionText>
      <JobDescriptionText>
        - Hiểu biết về OOP, UI/UX, Design Patterns, build tool (grunt, gulp,
        webpack…), có kinh nghiệm về Web server (Apache, Nginx, IIS, Tomcat) là
        một lợi thế.
      </JobDescriptionText>
      <JobDescriptionText>
        - Có kinh nghiệm làm việc với các hệ thống quản lý version, quản lý lỗi:
        Git, SVN, Jira...
      </JobDescriptionText>
      <JobDescriptionText>- Có kinh nghiệm làm việc nhóm</JobDescriptionText>

      <JobDescriptionText color="darkCharcoal" mt="20px" fontWeight="medium">
        Hồ sơ bao gồm:
      </JobDescriptionText>
      <JobDescriptionText>
        Ứng viên quan tâm vui lòng gửi CV nêu tóm tắt đầy đủ kinh nghiệm làm
        viêc và quá trình học tập của bản than về địa chỉ email:{' '}
        <JobDescriptionText color="primary" display="inline-block">
          linh.nguyen@ptvltd.com
        </JobDescriptionText>
      </JobDescriptionText>
    </>
  );
};

export default Frontend;
