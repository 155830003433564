import { Menu, Drawer } from 'antd';
import { Link } from 'react-scroll';
import styled from 'styled-components';

export const Wrapper = styled.div(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 999,
  height: '46px',
  paddingLeft: '20px',
  paddingRight: '20px',
  backgroundColor: theme.colors.white,
  [theme.media.tablet]: {
    height: '74px',
  },
  [theme.media.laptop]: {
    height: '76px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  [theme.media.laptopL]: {
    height: '80px',
  },
}));

export const Container = styled.div(({ theme }) => ({
  height: '100%',
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.media.laptopL]: {
    width: '1170px',
  },
}));

export const LogoContainer = styled.div(({ theme }) => ({
  display: 'flex',
  fontFamily: 'Quicksand',
  '& svg': {
    width: '22.05px',
    [theme.media.tablet]: {
      width: '26px',
    },
    [theme.media.laptop]: {
      width: '32px',
    },
  },
}));

export const LogoText = styled.div(() => ({
  marginLeft: '5.92px',
}));

export const LogoMainText = styled.div(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: theme.fontWeight.bolder,
  fontSize: '25px',
  textTransform: 'uppercase',
  color: theme.colors.primary,
  lineHeight: '32px',
  marginLeft: '6px',
  [theme.media.tablet]: {
    fontSize: '35px',
  },
  [theme.media.laptop]: {
    fontSize: '44px',
  },
  [theme.media.laptopL]: {
    lineHeight: '16px',
    marginLeft: 0,
    fontSize: theme.sizes.lg,
  },
}));

export const LogoSubText = styled.div(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: theme.fontWeight.medium,
  fontSize: theme.sizes.xs,
  color: theme.colors.gray65,
  lineHeight: '18px',
}));

export const MenuContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: '20px',
  [theme.media.laptop]: {
    gap: '38px',
  },
}));

export const MenuItem = styled(Link)(({ theme }) => ({
  fontWeight: theme.fontWeight.medium,
  textTransform: 'capitalize',
  color: theme.colors.darkCharcoal,
  fontSize: '17px',
  marginBottom: '30px',
  '&:hover': {
    color: theme.colors.primary,
  },
  '&.menu-item-active': {
    color: theme.colors.primary,
  },
  [theme.media.tablet]: {
    fontSize: theme.sizes.sm,
    marginBottom: 0,
  },
}));

export const DropDownContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const DropDownItem = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontWeight: theme.fontWeight.medium,
  fontSize: theme.sizes.sm,
  color: theme.colors.darkCharcoal,

  '& > div': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
  },
}));

export const DropDownToggle = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
  width: '200px',
  background: theme.colors.white,
  boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.08)',
  borderRadius: '5px',
  padding: '0 20px',

  '& .ant-dropdown-menu-item.ant-dropdown-menu-item-active, .ant-dropdown-menu-item:hover':
    {
      backgroundColor: theme.colors.white,
    },

  '& .ant-dropdown-menu-item': {
    padding: 0,
    paddingTop: 20,
    paddingBottom: 20,

    '&:first-child': {
      borderBottom: `1px solid ${theme.colors.lightGray}`,
    },
  },

  '& .ant-dropdown-menu-item-selected': {
    backgroundColor: theme.colors.white,
  },
}));

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .ant-drawer-header': {
    padding: '26.67px 26.67px 0',
  },
  '& .ant-drawer-header-title': {
    justifyContent: 'flex-end',
  },
  '& .ant-drawer-close': {
    marginRight: 0,
    padding: 0,
  },
  '& .ant-drawer-body': {
    padding: '36.67px 26.67px 26.67px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '& .ant-drawer-content-wrapper': {
    '@media (max-width: 377px)': {
      width: '100% !important',
    },
  },
}));

export const Image = styled.img`
  width: 22px;
  height: 16px;
`;
