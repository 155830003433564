import * as React from 'react';

function MissionIcon2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="30"
      height="40"
      viewBox="0 0 30 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12.6562 15.3125V17.6562H10.3125C9.88109 17.6562 9.53125 18.0061 9.53125 18.4375V21.5625C9.53125 21.9939 9.88109 22.3438 10.3125 22.3438H12.6562V24.6875C12.6562 25.1189 13.0061 25.4688 13.4375 25.4688H16.5625C16.9939 25.4688 17.3438 25.1189 17.3438 24.6875V22.3438H19.6875C20.1189 22.3438 20.4688 21.9939 20.4688 21.5625V18.4375C20.4688 18.0061 20.1189 17.6562 19.6875 17.6562H17.3438V15.3125C17.3438 14.8811 16.9939 14.5312 16.5625 14.5312H13.4375C13.006 14.5312 12.6562 14.881 12.6562 15.3125ZM14.2188 18.4375V16.0938H15.7812V18.4375C15.7812 18.8689 16.1311 19.2188 16.5625 19.2188H18.9062V20.7812H16.5625C16.1311 20.7812 15.7812 21.1311 15.7812 21.5625V23.9062H14.2188V21.5625C14.2188 21.1311 13.8689 20.7812 13.4375 20.7812H11.0938V19.2188H13.4375C13.8689 19.2188 14.2188 18.8689 14.2188 18.4375Z"
        fill="#E92134"
      />
      <path
        d="M25.9376 10.6053C25.9376 10.2249 25.6635 9.89973 25.2886 9.83535C21.723 9.22277 18.3326 7.71191 15.4838 5.46598C15.2001 5.24246 14.8 5.24246 14.5163 5.46598C11.6675 7.71191 8.27711 9.22277 4.71148 9.83535C4.33656 9.89973 4.0625 10.2249 4.0625 10.6053V14.8506C4.0625 22.9585 8.10477 30.8348 14.5573 35.2724C14.6906 35.3641 14.8453 35.41 15 35.41C15.1547 35.41 15.3094 35.3641 15.4427 35.2724C21.8883 30.8396 25.9376 22.9671 25.9376 14.8505V10.6053ZM24.3751 14.8505C24.3751 22.3004 20.7959 29.4593 15.0001 33.6719C9.20414 29.4593 5.62508 22.3004 5.62508 14.8505V11.2541C9.00961 10.5723 12.2272 9.13441 15.0001 7.0641C17.773 9.13441 20.9905 10.5723 24.3751 11.2541V14.8505Z"
        fill="#E92134"
      />
      <path
        d="M25.3125 29.6094C25.744 29.6094 26.0938 29.2596 26.0938 28.8281C26.0938 28.3967 25.744 28.0469 25.3125 28.0469C24.881 28.0469 24.5312 28.3967 24.5312 28.8281C24.5312 29.2596 24.881 29.6094 25.3125 29.6094Z"
        fill="#E92134"
      />
      <path
        d="M29.0625 6.24992C24.2333 6.24992 19.693 4.36937 16.2782 0.954531L15.5524 0.22875C15.2473 -0.07625 14.7527 -0.07625 14.4475 0.22875L13.7217 0.954531C10.307 4.3693 5.76672 6.24992 0.9375 6.24992C0.506094 6.24992 0.15625 6.59977 0.15625 7.03117V14.8507C0.15625 25.045 5.67492 34.932 14.6206 39.9016C14.7386 39.9671 14.8693 39.9999 15 39.9999C15.1307 39.9999 15.2615 39.9671 15.3794 39.9016C18.7555 38.026 21.6734 35.4548 24.0517 32.2595C24.3094 31.9134 24.2377 31.4239 23.8915 31.1663C23.5454 30.9087 23.0559 30.9805 22.7984 31.3266C20.6429 34.2223 18.0209 36.5736 15.0002 38.3206C6.91789 33.6406 1.71875 24.4789 1.71875 14.8506V7.79711C6.48531 7.61008 11.2173 5.66867 14.8266 2.05938L15 1.88602L15.1734 2.05938C18.5878 5.47383 23.2414 7.59938 28.2812 7.79711V14.8506C28.2812 18.4555 27.5697 22.0003 26.1663 25.3863C26.0012 25.785 26.1904 26.242 26.5889 26.4072C26.9877 26.5723 27.4445 26.3832 27.6098 25.9846C29.0921 22.408 29.8438 18.662 29.8438 14.8506V7.03117C29.8438 6.59969 29.4939 6.24992 29.0625 6.24992Z"
        fill="#E92134"
      />
    </svg>
  );
}

export default MissionIcon2;
