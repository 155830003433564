import * as React from 'react';

function QuoteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M5.32795 6.35961V12H0V8.01864C0 6.4699 0.243649 5.05165 0.730809 3.76078C1.21797 2.47145 2.03675 1.21631 3.18568 0L5.17097 1.65903C3.81221 3.24505 3.04626 4.81087 2.87165 6.35961H5.32795ZM14 6.35961V12H8.67205V8.01864C8.67205 6.4699 8.91562 5.05165 9.40278 3.76078C9.88994 2.47145 10.7087 1.21631 11.8577 0L13.843 1.65903C12.4843 3.24505 11.7183 4.81087 11.5437 6.35961H14Z"
        fill="#E92134"
      />
    </svg>
  );
}

export default QuoteIcon;
