const photos = [
  {
    thumbnail: '/images/gallery-thumbnail-1.png',
    original: '/images/gallery-1.jpg',
  },
  {
    thumbnail: '/images/gallery-thumbnail-2.png',
    original: '/images/gallery-2.jpg',
  },
  {
    thumbnail: '/images/gallery-thumbnail-3.png',
    original: '/images/gallery-3.jpg',
  },
  {
    thumbnail: '/images/gallery-thumbnail-4.png',
    original: '/images/gallery-4.jpg',
  },
  {
    thumbnail: '/images/gallery-thumbnail-5.png',
    original: '/images/gallery-5.jpg',
  },
  {
    thumbnail: '/images/gallery-thumbnail-6.png',
    original: '/images/gallery-6.jpg',
  },
  {
    thumbnail: '/images/gallery-thumbnail-7.png',
    original: '/images/gallery-7.jpg',
  },
  {
    thumbnail: '/images/gallery-thumbnail-8.png',
    original: '/images/gallery-8.jpg',
  },
  {
    thumbnail: '/images/gallery-thumbnail-9.png',
    original: '/images/gallery-9.jpg',
  },
];

export { photos };
