import styled from 'styled-components';

import { Color, FontWeight } from '@Theme/theme';

interface JobDescriptionTextProps {
  mt?: string;
  fontWeight?: FontWeight;
  display?: string;
  color?: Color;
}

export const Wrapper = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'linear-gradient(0deg, #f7f7f7, #f7f7f7), #ffffff',
  paddingTop: '52px',
  paddingBottom: '50px',
  [theme.media.laptop]: {
    paddingTop: '49px',
    paddingBottom: '88px',
  },
  [theme.media.laptopL]: {
    paddingTop: '79px',
    paddingBottom: '118px',
  },
}));

export const SubTitle = styled.p(({ theme }) => ({
  color: theme.colors.secondary,
  fontSize: theme.sizes.sm,
  textAlign: 'center',
  marginTop: '20px',
  marginBottom: '30px',
  padding: '0 16px',
  [theme.media.tablet]: {
    padding: 0,
    marginBottom: '42px',
  },
}));

export const CollapseContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  width: '100%',
  padding: '0 16px',
  [theme.media.tablet]: {
    padding: '0 20px',
  },
  [theme.media.laptop]: {
    padding: '0 27px',
  },
  [theme.media.laptopL]: {
    padding: 0,
    width: '970px',
  },
}));

export const JobDescriptionText = styled.p<JobDescriptionTextProps>(
  ({ theme, color, mt, display, fontWeight }) => ({
    marginTop: mt,
    display: display,
    fontStyle: 'normal',
    fontWeight: fontWeight
      ? theme.fontWeight[fontWeight]
      : theme.fontWeight.regular,
    fontSize: theme.sizes.sm,
    color: color ? theme.colors[color] : theme.colors.gray,
  })
);
