import styled from 'styled-components';

export const Wrapper = styled.div(({ theme }) => ({
  textAlign: 'center',
  backgroundColor: theme.colors.white,
  paddingTop: 40,
  paddingBottom: 37,
  paddingLeft: 16,
  paddingRight: 16,
  [theme.media.tablet]: {
    paddingTop: 53,
    paddingBottom: 60,
    paddingLeft: 26,
    paddingRight: 26,
  },
  [theme.media.laptop]: {
    paddingTop: 63,
    paddingBottom: 70,
    paddingLeft: 53,
    paddingRight: 53,
  },
  [theme.media.laptopL]: {
    paddingTop: 82,
    paddingBottom: 100,
  },
}));

export const Container = styled.div(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  [theme.media.laptopL]: {
    width: '1096px',
  },
}));

export const SubTitle = styled.p(({ theme }) => ({
  color: theme.colors.secondary,
  fontSize: theme.sizes.sm,
  textAlign: 'center',
  marginTop: 20,
  marginBottom: 32,
  [theme.media.tablet]: {
    marginBottom: 41,
  },
  [theme.media.laptop]: {
    marginBottom: 50,
  },
}));

export const NahtServiceContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.media.tablet]: {
    flexDirection: 'row',
  },
  '& img': {
    width: '315px',
    [theme.media.laptop]: {
      width: '500px',
    },
    [theme.media.laptopL]: {
      width: 'auto',
    },
  },
}));

export const LeberServiceContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.media.tablet]: {
    flexDirection: 'row',
    marginTop: '50px',
    marginLeft: '63px',
    marginRight: '63px',
  },
  [theme.media.laptop]: {
    marginLeft: '87px',
    marginRight: '87px',
  },
  [theme.media.laptopL]: {
    marginLeft: '141px',
    marginRight: '141px',
  },
  '& img': {
    width: '209px',
    [theme.media.laptop]: {
      width: 'auto',
    },
  },
}));

export const Service = styled.div(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.media.tablet]: {
    alignItems: 'flex-start',
  },
}));

export const NahtService = styled(Service)(({ theme }) => ({
  marginLeft: 0,
  marginTop: 20,
  [theme.media.tablet]: {
    marginTop: 0,
    marginLeft: 30,
  },
  [theme.media.laptop]: {
    marginLeft: 50,
  },
  [theme.media.laptopL]: {
    marginLeft: 90,
  },
}));

export const LeberService = styled(Service)(({ theme }) => ({
  marginLeft: 0,
  marginTop: 20,
  [theme.media.tablet]: {
    marginTop: 0,
  },
  [theme.media.laptopL]: {
    marginRight: 90,
  },
}));

export const ServiceTitle = styled.p(({ theme }) => ({
  fontSize: theme.sizes.lg,
  fontWeight: theme.fontWeight.medium,
  color: theme.colors.darkCharcoal,
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  '::after': {
    content: '""',
    width: '6px',
    height: '6px',
    background: theme.colors.primary,
    position: 'absolute',
    borderRadius: '50%',
    top: 'calc(50% - 3px)',
    right: '-12px',
  },
}));

export const ServiceSubTitle = styled(SubTitle)(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  marginTop: 6,
  marginBottom: 38,
  whiteSpace: 'pre-line',
  [theme.media.tablet]: {
    textAlign: 'left',
    marginTop: 14,
    width: '371px',
  },
}));
