import React from 'react';
import { Layout } from 'antd';

import Missions from './missions';
import AboutUs from './aboutUs';
import ContactUs from './contactUs';
import Footer from './footer';
import OurService from './ourServices';
import Header from './header';
import Banner from './banner';
import OurRecruitment from './ourRecruitment';
import ContactUsForm from './contactUsForm';
import PhotoGallery from './photoGallery';

const LandingPage = () => {
  return (
    <Layout>
      <Header />
      <Banner />
      <Missions />
      <AboutUs />
      <OurService />
      <ContactUs />
      <PhotoGallery />
      <OurRecruitment />
      <ContactUsForm />
      <Footer />
    </Layout>
  );
};

export default LandingPage;
