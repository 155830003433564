import React from 'react';
import { Wrapper, Text } from './Footer.style';

const Footer: React.FC = (): React.ReactElement => {
  return (
    <Wrapper>
      <Text>© 2022 PATIENT TECHNOLOGIES VIETNAM CO.,LTD</Text>
      <Text>ペイシェント テクノロジーズ ベトナム</Text>
    </Wrapper>
  );
};

export default Footer;
