import React from 'react';

import { useTranslation } from 'react-i18next';

import { OUR_SERVICES_SECTION_ID } from '@Constant';
import { Title } from '@Components';
import {
  Wrapper,
  LeberService,
  NahtService,
  Container,
  SubTitle,
  ServiceTitle,
  ServiceSubTitle,
  NahtServiceContainer,
  LeberServiceContainer,
} from './OurService.style';

const OurService = () => {
  const { t } = useTranslation();

  return (
    <Wrapper id={OUR_SERVICES_SECTION_ID}>
      <Container>
        <Title>{t('ourServices.title')}</Title>
        <SubTitle>{/* {t('ourServices.description')} */}</SubTitle>
        <NahtServiceContainer>
          <img src="/images/naht.png" />
          <NahtService>
            <ServiceTitle>NAHT</ServiceTitle>
            <ServiceSubTitle>
              {t('ourServices.nahtDescription')}
            </ServiceSubTitle>
          </NahtService>
        </NahtServiceContainer>
        <LeberServiceContainer>
          <LeberService>
            <ServiceTitle>LEBER</ServiceTitle>
            <ServiceSubTitle>
              {t('ourServices.leberDescription')}
            </ServiceSubTitle>
          </LeberService>
          <img src="/images/leber.png" />
        </LeberServiceContainer>
      </Container>
    </Wrapper>
  );
};

export default OurService;
