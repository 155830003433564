import styled from 'styled-components';

import { Color, Size, FontWeight } from '@Theme/theme';

interface IButton {
  bdr?: number;
  onClick?: () => void;
  color: Color;
  width?: string;
  height?: string;
  border?: string;
  margin?: string;
  padding?: string;
  fontSize?: Size;
  background: Color;
  lineHeight?: string;
  fontWeight?: FontWeight;
  disabled?: boolean;
}

const Button = styled.button<IButton>(
  ({
    bdr,
    theme,
    color,
    width,
    height,
    border,
    margin,
    padding,
    fontSize,
    background,
    lineHeight,
    fontWeight,
  }) => ({
    margin,
    padding,
    cursor: 'pointer',
    textAlign: 'center',
    width: width || 163,
    height: height || 48,
    borderRadius: bdr || 8,
    border: border || 'none',
    color: theme.colors[color],
    lineHeight: lineHeight || '26px',
    background: theme.colors[background],
    fontSize: fontSize ? theme.sizes[fontSize] : theme.sizes.sm,
    fontWeight: fontWeight
      ? theme.fontWeight[fontWeight]
      : theme.fontWeight.medium,
    '&:hover': {
      cursor: 'pointer',
    },
    '&:disabled': {
      background: '#FFF0F0',
      cursor: 'no-drop',
      color: '#E92134',
      opacity: 0.6,
    },
    '&:focus': {
      border: 'none',
    },
  })
);

export default Button;
