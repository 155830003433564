import { createGlobalStyle } from 'styled-components';
import { theme } from '@Theme/theme';
import 'antd/dist/antd.min.css';
import 'react-image-lightbox/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const GlobalStyles = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
        font-size: ${theme.sizes.sm};
        font-weight: ${theme.fontWeight.regular};
        font-family: 'Be Vietnam Pro', sans-serif;
    }

    * {
        box-sizing: border-box;
    }

    p {
        margin-bottom: 0;
    }
`;
