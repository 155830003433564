import styled from 'styled-components';

interface TitleProps {
  position?: 'left' | 'center';
  textAlign?: 'left' | 'center';
}

const Title = styled.h1<TitleProps>(
  ({ theme, position = 'center', textAlign = 'center', color }) => ({
    fontSize: theme.sizes.xl,
    marginBottom: 0,
    position: 'relative',
    display: 'inline-block',
    textTransform: 'capitalize',
    textAlign: textAlign,
    color: color || theme.colors.darkCharcoal,
    fontWeight: theme.fontWeight.medium,
    lineHeight: '25px',
    width: '100%',
    '&::after': {
      content: '""',
      width: '40px',
      height: '3px',
      borderRadius: '3px',
      backgroundColor: color || theme.colors.primary,
      position: 'absolute',
      bottom: -8,
      left: position === 'center' ? 'calc(50% - 20px)' : 1.5,
    },
    [theme.media.mobileL]: {
      lineHeight: '33px',
      fontSize: theme.sizes.xxl,
    },
  })
);

export default Title;
