import React from 'react';

import { Row, Col } from 'antd';
import { OUR_MISSION_SECTION_ID } from '@Constant';
import { Title, Card } from '@Components';
import { MissionIcon1, MissionIcon2, MissionIcon3 } from '@Icons';
import { useTranslation } from 'react-i18next';
import {
  WrapperMissions,
  BlockMissons,
  DescriptionMissons,
} from './Missions.style';
import { Container } from '../LandingPage.style';

const Missions = () => {
  const { t } = useTranslation();
  return (
    <WrapperMissions id={OUR_MISSION_SECTION_ID}>
      <Container>
        <Title>{t('missions.title')}</Title>
        {/* <DescriptionMissons>
          {t('missions.missionDescription')}
        </DescriptionMissons>*/}
        <BlockMissons>
          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Card
                icon={<MissionIcon1 />}
                content={t('missions.missionContent1')}
              />
            </Col>
            <Col className="card-two" xs={24} md={8}>
              <Card
                icon={<MissionIcon2 />}
                content={t('missions.missionContent2')}
              />
            </Col>
            <Col xs={24} md={8}>
              <Card
                icon={<MissionIcon3 />}
                content={t('missions.missionContent3')}
              />
            </Col>
          </Row>
        </BlockMissons>
      </Container>
    </WrapperMissions>
  );
};

export default Missions;
