import React from 'react';

import styled from 'styled-components';

interface CardProps {
  icon: React.ReactNode;
  content: React.ReactNode;
}

const CardContainer = styled.div(({ theme }) => ({
  background: theme.colors.white,
  boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.08)',
  borderRadius: '10px',
  padding: '30px 20px 29px 20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '100%',
  [theme.media.tablet]: {
    padding: '30px 16px 49px 16px',
  },
  [theme.media.tablet]: {
    padding: '30px 16px 49px 16px',
  },
  [theme.media.laptop]: {
    padding: '30px 20px 29px 20px',
  },
  [theme.media.desktop]: {
    padding: '30px 30px 41px 30px',
  },
}));

const IconContainer = styled.div(({ theme }) => ({
  width: '80px',
  height: '80px',
  background: theme.colors.lavenderBlush,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 21,
  [theme.media.laptop]: {
    marginBottom: 31,
  },
}));

const CardContent = styled.div(({ theme }) => ({
  whiteSpace: 'pre-line',
  textAlign: 'center',
  color: theme.colors.secondary,
  fontSize: theme.sizes.sm,
  fontWeight: theme.fontWeight.regular,
  [theme.media.tablet]: {
    height: '90px',
  },
  [theme.media.laptop]: {
    height: 'auto',
  },
}));

const Card: React.FC<CardProps> = ({ icon, content }) => {
  return (
    <CardContainer>
      <IconContainer>{icon}</IconContainer>
      <CardContent>{content}</CardContent>
    </CardContainer>
  );
};

export default Card;
