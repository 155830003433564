import { Collapse } from 'antd';
import styled from 'styled-components';

import { theme } from '@Theme/theme';

interface StyledCollapseProps {
  isActive: boolean;
}

const StyledCollapse = styled(Collapse)<StyledCollapseProps>(
  ({ theme, isActive }) => ({
    color: theme.colors.secondary,
    borderRadius: 5,
    borderEndStartRadius: isActive ? 0 : 5,
    borderEndEndRadius: isActive ? 0 : 5,
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.04)',
    backgroundColor: theme.colors.white,
    '.ant-collapse-header': {
      height: '100%',
    },
    '&.ant-collapse  .ant-collapse-header': {
      height: '70px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 16,
      paddingRight: 50,
      [theme.media.mobileL]: {
        paddingLeft: 20,
        paddingRight: 60,
      },
      [theme.media.laptop]: {
        paddingLeft: 30,
        paddingRight: 93,
      },
    },
    '.ant-collapse-header-text': {
      width: '100%',
      fontStyle: 'normal',
      fontWeight: theme.fontWeight.medium,
      color: theme.colors.darkCharcoal,
      fontSize: theme.sizes.md,
      [theme.media.tablet]: {
        fontSize: theme.sizes.lg,
      },
    },
    '.ant-collapse-item': {
      height: '100%',
    },
    '&.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow':
      {
        right: '19px',
        [theme.media.laptop]: {
          right: '31px',
        },
      },
    '&.ant-collapse-borderless .ant-collapse-content': {
      backgroundColor: theme.colors.white,
      borderEndStartRadius: 5,
      borderEndEndRadius: 5,
      padding: '0 16px 20px 16px',
      [theme.media.mobileL]: {
        padding: '0 20px 30px 20px',
      },
      [theme.media.laptop]: {
        padding: '0 30px 30px 30px',
      },
    },
    '&.ant-collapse-borderless> .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box':
      {
        padding: 0,
        paddingTop: 20,
        borderTop: `1px solid ${theme.colors.lightGray}`,
        [theme.media.tablet]: {
          paddingTop: 30,
        },
      },
  })
);

const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  ${theme.media.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;

const SubHeaderContainer = styled.div`
  display: flex;
  gap: 20px;
  ${theme.media.tablet} {
    gap: 30px;
  }
`;

const SubHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const SubHeaderText = styled.span(({ theme }) => ({
  fontSize: theme.sizes.xs,
  color: theme.colors.gray,
  fontWeight: theme.fontWeight.medium,
  lineHeight: '18px',
  [theme.media.tablet]: {
    fontSize: theme.sizes.sm,
  },
}));

export {
  SubHeader,
  SubHeaderText,
  StyledCollapse,
  HeaderContainer,
  SubHeaderContainer,
};
