const VIETNAMESE_LANGUAGE = 'vi';
const JAPANESE_LANGUAGE = 'ja';

const OUR_MISSION_SECTION_ID = 'our-mission';
const ABOUT_US_SECTION_ID = 'about-us';
const OUR_SERVICES_SECTION_ID = 'our-services';
const PHOTO_GALLERY_SECTION_ID = 'photo-gallery';
const OUR_RECRUITMENT_SECTION_ID = 'our-recruitment';
const CONTACT_US_SECTION_ID = 'contact-us';

const LANGUAGE_DROPDOWN_POPUP_CONTAINER_ID = 'language-dropdown';

const menuItems = [
  {
    key: 1,
    id: OUR_MISSION_SECTION_ID,
    label: 'header.ourMission',
  },
  {
    key: 2,
    id: ABOUT_US_SECTION_ID,
    label: 'header.aboutUs',
  },
  {
    key: 3,
    id: OUR_SERVICES_SECTION_ID,
    label: 'header.ourServices',
  },
  {
    key: 4,
    id: PHOTO_GALLERY_SECTION_ID,
    label: 'header.gallery',
  },
  {
    key: 5,
    id: OUR_RECRUITMENT_SECTION_ID,
    label: 'header.careers',
  },
  {
    key: 6,
    id: CONTACT_US_SECTION_ID,
    label: 'header.contactUs',
  },
];

export {
  menuItems,
  VIETNAMESE_LANGUAGE,
  JAPANESE_LANGUAGE,
  ABOUT_US_SECTION_ID,
  CONTACT_US_SECTION_ID,
  OUR_MISSION_SECTION_ID,
  OUR_SERVICES_SECTION_ID,
  PHOTO_GALLERY_SECTION_ID,
  OUR_RECRUITMENT_SECTION_ID,
  LANGUAGE_DROPDOWN_POPUP_CONTAINER_ID,
};
