import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { Button } from '@Components';
import { CONTACT_US_SECTION_ID } from '@Constant';
import {
  BlockContactUs,
  WrapperTextContactUs,
  WrapperBtnContactUs,
} from './ContactUs.style';
import { Container } from '../LandingPage.style';

const ContactUs = () => {
  const { t } = useTranslation('translation');

  return (
    <BlockContactUs>
      <Container>
        <Row gutter={16}>
          <Col xs={24} lg={20}>
            <WrapperTextContactUs>
              <p>{t('contactUsDes')}</p>
            </WrapperTextContactUs>
          </Col>
          <Col xs={24} lg={4}>
            <WrapperBtnContactUs>
              <Link
                to={CONTACT_US_SECTION_ID}
                spy
                smooth
                duration={900}
                offset={-46}>
                <Button background={'primary'} color={'white'}>
                  {t('contactUs')}
                </Button>
              </Link>
            </WrapperBtnContactUs>
          </Col>
        </Row>
      </Container>
    </BlockContactUs>
  );
};
export default ContactUs;
