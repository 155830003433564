import * as React from 'react';

function CarouselArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M15 7.5H1" strokeMiterlimit="10" />
      <path
        d="M6.46414 1.5L1 7.5L6.46414 13.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
}

export default CarouselArrowIcon;
