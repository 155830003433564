import * as React from 'react';

function LocationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M10.0001 0C6.00613 0 2.75684 3.2493 2.75684 7.2432C2.75684 12.1998 9.23883 19.4763 9.5148 19.7836C9.77402 20.0723 10.2266 20.0718 10.4854 19.7836C10.7613 19.4763 17.2433 12.1998 17.2433 7.2432C17.2432 3.2493 13.994 0 10.0001 0ZM10.0001 10.8875C7.99062 10.8875 6.35586 9.25266 6.35586 7.2432C6.35586 5.23375 7.99066 3.59898 10.0001 3.59898C12.0095 3.59898 13.6443 5.23379 13.6443 7.24324C13.6443 9.25269 12.0095 10.8875 10.0001 10.8875Z"
        fill="#E92134"
      />
    </svg>
  );
}

export default LocationIcon;
