import axios from 'axios';

import { httpStatus } from './httpStatus';

const config = {
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: (status: number) => {
    return (
      status >= httpStatus.StatusOK &&
      status < httpStatus.StatusHTTPVersionNotSupported
    );
  },
  timeout: 120 * 1000,
};
const httpClient = axios.create(config);

httpClient.interceptors.request.use(
  (config: any) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default httpClient;
