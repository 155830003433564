import * as React from 'react';

function MissionIcon1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="38"
      viewBox="0 0 40 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M31.4704 7.28125C31.4704 4.56281 29.6915 0.25 24.9181 0.25C22.9568 0.25 21.3071 1.17461 20.0001 3.00227C18.6931 1.17461 17.0433 0.25 15.0821 0.25C10.3086 0.25 8.52979 4.56281 8.52979 7.28125C8.52979 11.9682 12.1878 14.9 17.185 19.1732C17.9193 19.801 18.6786 20.4503 19.4881 21.1527C19.635 21.2801 19.8175 21.3438 20.0001 21.3438C20.1827 21.3438 20.3652 21.2801 20.5121 21.1527C21.3216 20.4503 22.0809 19.801 22.8152 19.1732C28.0469 14.6995 31.4704 11.8691 31.4704 7.28125ZM20.0001 19.5294C19.3758 18.9906 18.7804 18.4815 18.2005 17.9855C12.9722 13.515 10.0923 11.0523 10.0923 7.28125C10.0923 4.7232 11.7658 1.8125 15.0821 1.8125C16.8128 1.8125 18.1973 2.80625 19.3146 4.85047C19.4516 5.10109 19.7145 5.25703 20.0001 5.25703C20.2857 5.25703 20.5486 5.10109 20.6856 4.85047C21.8029 2.80625 23.1874 1.8125 24.9181 1.8125C28.1881 1.8125 29.9079 4.68 29.9079 7.28125C29.9079 11.0523 27.028 13.515 21.7997 17.9856C21.2198 18.4815 20.6244 18.9906 20.0001 19.5294Z"
        fill="#E92134"
      />
      <path
        d="M5.61391 30.8839L9.375 32.7641V36.9688C9.375 37.4002 9.72484 37.75 10.1562 37.75H18.4375C18.8689 37.75 19.2188 37.4002 19.2188 36.9688V26.7695C19.2188 24.3759 17.4232 22.391 15.048 22.1529L14.9841 22.146C13.6094 22.0082 11.1752 21.0679 10.214 20.1068L8.45945 18.353C7.86906 17.7627 7.08445 17.4375 6.25 17.4375V11.1875C6.25 9.46438 4.84812 8.0625 3.125 8.0625C1.40188 8.0625 0 9.46438 0 11.1875V21.8C0 25.6448 2.17172 29.1624 5.61391 30.8839ZM1.5625 11.1875C1.5625 10.3259 2.26344 9.625 3.125 9.625C3.98656 9.625 4.6875 10.3259 4.6875 11.1875V17.8563C3.73469 18.4073 3.125 19.4345 3.125 20.5625C3.125 21.397 3.45016 22.1816 4.04055 22.7719L8.04133 26.7734C8.34633 27.0786 8.84102 27.0786 9.14617 26.7735C9.45125 26.4685 9.45133 25.9738 9.14625 25.6687L5.14547 21.667C4.85008 21.3718 4.6875 20.9795 4.6875 20.5625C4.6875 19.9012 5.10602 19.3091 5.72906 19.089C5.72914 19.089 5.7293 19.0889 5.72937 19.0888C5.72953 19.0887 5.72977 19.0888 5.72992 19.0887C6.26883 18.8974 6.90805 19.0113 7.35469 19.458L9.10922 21.2119C10.9206 23.0233 14.8782 23.7048 14.8861 23.707C16.4653 23.8653 17.6562 25.182 17.6562 26.7695V36.1875H10.9374V32.2812C10.9374 31.9853 10.7702 31.7148 10.5055 31.5825L6.31266 29.4864C3.38273 28.021 1.5625 25.0758 1.5625 21.8V11.1875Z"
        fill="#E92134"
      />
      <path
        d="M35.8594 29.7812C36.2908 29.7812 36.6406 29.4315 36.6406 29C36.6406 28.5685 36.2908 28.2188 35.8594 28.2188C35.4279 28.2188 35.0781 28.5685 35.0781 29C35.0781 29.4315 35.4279 29.7812 35.8594 29.7812Z"
        fill="#E92134"
      />
      <path
        d="M36.875 8.0625C35.1519 8.0625 33.75 9.46438 33.75 11.1875V17.4375C32.9155 17.4375 32.1309 17.7627 31.5407 18.353L29.7859 20.107C28.8248 21.068 26.3906 22.0083 25.0098 22.1466L24.958 22.1523C22.5768 22.391 20.7812 24.376 20.7812 26.7695V36.9688C20.7812 37.4002 21.1311 37.75 21.5625 37.75H29.8438C30.2752 37.75 30.625 37.4002 30.625 36.9688V32.7641L33.182 31.4859C33.5679 31.2929 33.7244 30.8237 33.5315 30.4377C33.3385 30.0519 32.8692 29.8952 32.4834 30.0883L29.4944 31.5825C29.2298 31.7148 29.0625 31.9853 29.0625 32.2812V36.1875H22.3438V26.7695C22.3438 25.182 23.5346 23.8653 25.1199 23.7064L25.1716 23.7008C26.8662 23.5309 29.6721 22.4304 30.8905 21.212L32.6454 19.458C33.0933 19.0099 33.7338 18.8984 34.27 19.0887C34.2702 19.0888 34.2704 19.0887 34.2705 19.0888C34.2706 19.0888 34.2708 19.0889 34.2709 19.089C34.894 19.3091 35.3125 19.9012 35.3125 20.5625C35.3125 20.9795 35.1499 21.3718 34.8546 21.6672L30.8538 25.6687C30.5487 25.9738 30.5488 26.4685 30.8539 26.7736C31.1588 27.0787 31.6537 27.0787 31.9587 26.7735L35.9595 22.772C36.5498 22.1816 36.875 21.397 36.875 20.5625C36.875 19.4345 36.2653 18.4073 35.3125 17.8563V11.1875C35.3125 10.3259 36.0134 9.625 36.875 9.625C37.7366 9.625 38.4375 10.3259 38.4375 11.1875V21.8C38.4375 23.2294 38.0768 24.6484 37.3943 25.9038C37.1881 26.2829 37.3284 26.7572 37.7075 26.9633C38.0865 27.1694 38.561 27.0293 38.767 26.6501C39.5737 25.1664 40 23.4893 40 21.8V11.1875C40 9.46438 38.5981 8.0625 36.875 8.0625Z"
        fill="#E92134"
      />
    </svg>
  );
}

export default MissionIcon1;
