import styled from 'styled-components';

import { theme } from '@Theme/theme';

export const Wrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.white,
  paddingTop: '49px',
  paddingBottom: '50px',
  paddingRight: '15px',
  paddingLeft: '15px',
  [theme.media.tablet]: {
    paddingTop: '49px',
    paddingBottom: '44px',
  },
  [theme.media.desktop]: {
    paddingTop: '59px',
    paddingBottom: '63px',
  },
  [theme.media.desktopL]: {
    paddingTop: '79px',
    paddingBottom: '93px',
  },
}));

export const CarouselItem = styled.div(({ theme }) => ({
  width: '128px',
  height: '96px',
  cursor: 'pointer',
  '& img': {
    borderRadius: '5px',
    width: '100%',
    height: '100%',
  },
  padding: '0 4px',
  [theme.media.tablet]: {
    width: '176px',
    height: '132px',
  },
  [theme.media.laptop]: {
    width: '164px',
    height: '123px',
    padding: '0 2.5px',
  },
}));

export const CarouselContainer = styled.div(({ theme }) => ({
  width: '264px',
  margin: '30px auto 0',
  [theme.media.tablet]: {
    width: '538px',
    margin: '50px auto 0',
  },
  [theme.media.laptop]: {
    width: '840px',
  },
  '@media (min-width: 1365px)': {
    width: '1180px',
  },
}));

export const ArrowContainer = styled.div`
  &.slick-prev:before,
  &.slick-next:before {
    display: none;
  }

  &.slick-prev {
    display: flex !important;
    left: -46px;
    & svg {
      stroke: ${theme.colors.darkCharcoal};
      &:hover {
        stroke: ${theme.colors.primary};
      }
    }
    ${theme.media.tablet} {
      left: -66px;
    }
  }
  &.slick-next {
    display: flex !important;
    right: -46px;
    & svg {
      transform: rotate(180deg);
      stroke: ${theme.colors.darkCharcoal};
      &:hover {
        stroke: ${theme.colors.primary};
      }
    }
    ${theme.media.tablet} {
      right: -66px;
    }
  }

  width: 36px;
  height: 36px;
  background-color: ${theme.colors.white};
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
