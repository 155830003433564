import styled from 'styled-components';
import { theme } from '@Theme/theme';

export const WrapperMissions = styled.div`
  background: #fff;
  padding: 60px 20px;
`;
export const DescriptionMissons = styled.div`
  width: 70%;
  margin: auto;
  margin-top: 20px;
  text-align: center;
  font-size: ${theme.sizes.sm};
  font-weight: ${theme.fontWeight.regular};
  ${theme.media.mobileS} {
    width: 100%;
  }
  ${theme.media.laptop} {
    width: 70%;
  }
`;
export const BlockMissons = styled.div`
  margin-top: 45px;
  .card-two {
    ${theme.media.mobileS} {
      margin: 15px 0px;
    }
    ${theme.media.tablet} {
      margin: 0px;
    }
  }
`;
