import React from 'react';
import styled from 'styled-components';

import { WrapperAreaErrorMessage, ErrorMessage } from '@Theme/CommonStyle';

interface ITextArea {
  height?: string;
  label?: React.ReactNode;
  width?: number;
  bdr?: string; // border radius
  fsLabel?: number; // font size
  fwLabel?: number; // font weight
  lhLabel?: string; // line height
  fs?: number;
  fw?: number;
  color?: string;
  lh?: string;
  padding?: string;
  value?: string;
  name?: string;
  type?: string;
  onChange?: any;
  error?: string;
  marginForm?: string;
  placeholder?: string;
  disabled?: boolean;
}

const FormControl = styled.div<ITextArea>(({ marginForm }) => ({
  position: 'relative',
  margin: marginForm,
}));

const Label = styled.div<ITextArea>(
  ({ theme, fsLabel, fwLabel, lhLabel, disabled, color }) => ({
    color: color || theme.colors.white,
    fontSize: fsLabel || theme.sizes.sm,
    fontWeight: fwLabel || 500,
    lineHeight: lhLabel || '12px',
    marginBottom: 8,
  })
);

const TextAreaStyled = styled.textarea<ITextArea>(
  ({ theme, width, bdr, height, fs, fw, padding, lh, disabled, color }) => ({
    height: height || 62,
    width: width || '100%',
    borderRadius: bdr || theme.sizes.xs,
    border: `1px solid ${theme.colors.textFieldBackground}`,
    fontSize: fs || theme.sizes.sm,
    fontWeight: fw || theme.fontWeight.medium,
    lineHeight: lh || '24px',
    padding: padding || 16,
    outline: 'none',
    color: color || theme.colors.white,
    background: disabled ? theme.colors.background : 'none',
    ':focus': {
      border: `1px solid ${theme.colors.outline}`,
    },
    '::-webkit-scrollbar': {
      width: 16,
    },
    '::-webkit-scrollbar-track': {
      margin: '9px 0px 9px',
      borderLeft: '4px solid #FFFFFF',
      borderRight: '4px solid #FFFFFF',
      background: theme.colors.white,
      borderRadius: 7,
    },
    '::-webkit-scrollbar-thumb': {
      background: theme.colors.border,
      borderRadius: 7,
      height: '8px',
      borderLeft: '4px solid #FFFFFF',
      borderRight: '4px solid #FFFFFF',
    },
  })
);

const TextAreaCustom: React.FC<ITextArea> = ({
  label,
  error,
  fsLabel,
  fwLabel,
  lhLabel,
  marginForm,
  placeholder,
  disabled,
  color,
  ...rest
}) => {
  return (
    <FormControl marginForm={marginForm}>
      {label && (
        <Label
          fsLabel={fsLabel}
          fwLabel={fwLabel}
          lhLabel={lhLabel}
          color={color}
          disabled={disabled}>
          {label}
        </Label>
      )}
      <TextAreaStyled
        {...rest}
        color={color}
        spellCheck="false"
        style={{ resize: 'none' }}
        placeholder={placeholder}
        disabled={disabled}
      />
      {error && (
        <WrapperAreaErrorMessage>
          <ErrorMessage>{error}</ErrorMessage>
        </WrapperAreaErrorMessage>
      )}
    </FormControl>
  );
};

export default TextAreaCustom;
